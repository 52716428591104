import React from 'react';
import AppMain from './scenes/AppMain';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import pink from '@material-ui/core/colors/pink';

// All the following keys are optional, as default values are provided.
const theme = createMuiTheme({
  palette: {
    primary: blueGrey,
    secondary: pink,
    error: pink,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>

    <div className="App">
      <AppMain />
    </div>
    </ThemeProvider>
  );
}



export default App;
