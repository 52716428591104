import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';


const styles = theme => ({
  table: {
    height: 440,
    overflow: "auto",
  },
  form: {
    minHeight: 220,
    padding: "5px",
  },
  formButton: {
    padding: "5px",
  },
  formText: {
    padding: "10px",
  },
});

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'version', label: 'Version', minWidth: 100}
]

const data = require('../data/data.json');

const maxPage = 6;


class C2Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeData: data,
      currentPage: 0,
      serverLangFilter: [],
      agentLangFilter: [],
      multiUserFilter: false,
      uiFilter: [],
      apiFilter: false,
      channelsFilter: [],
      agentOSFilter: [],
      keyExchangeFilter: [],
      miscCapFilter: [],
      dashboardFilter: false,
      activeFilter: false,
      supportFilter: []
    }
  }

  componentDidMount() {
    this.rebuildActiveData();
  }

  rebuildActiveData() {
    var newData = {};

    for (var [name, c2] of Object.entries(data)) {
      var include = true;

      if (this.state.multiUserFilter && !c2['multi_user']) {
        include = false;
      }

      if (this.state.apiFilter && !c2['api']) {
        include = false;
      }

      if (this.state.dashboardFilter && !c2['dashboard']) {
        include = false;
      }

      if (this.state.activeFilter && !c2['active']) {
        include = false;
      }

      for (var i = 0; i < this.state.serverLangFilter.length; i++) {
        const containsItem = c2['server_langs'].includes(this.state.serverLangFilter[i]);
        if (!containsItem) {
          include = false;
        }
      }

      for (i = 0; i < this.state.agentLangFilter.length; i++) {
        const containsItem = c2['agent_langs'].includes(this.state.agentLangFilter[i]);
        if (!containsItem) {
          include = false;
        }
      }

      for (i = 0; i < this.state.channelsFilter.length; i++)  {
        const containsItem = c2['channels'].includes(this.state.channelsFilter[i]);
        if (!containsItem) {
          include = false;
        }
      }

      for (i = 0; i < this.state.agentOSFilter.length; i++) {
        const containsItem = c2['agent_os'].includes(this.state.agentOSFilter[i]);
        if (!containsItem) {
          include = false;
        }
      }

      for (i = 0; i < this.state.miscCapFilter.length; i++) {
        const containsItem = c2['capabilities'].includes(this.state.miscCapFilter[i]);
        if (!containsItem) {
          include = false;
        }
      }

      for (i = 0; i < this.state.supportFilter.length; i++)  {
        const containsItem = c2['support'].includes(this.state.supportFilter[i]);
        if (!containsItem) {
          include = false;
        }
      }

      var uiGood = false;
      if (this.state.uiFilter.length === 0) {
        uiGood = true;
      }
      for (i = 0; i < this.state.uiFilter.length; i++) {
        if (this.state.uiFilter[i] === c2['ui']) {
          uiGood = true;
        }
      }
      if (!uiGood) {
        include = false;
      }

      if (include) {
        newData[name] = c2;
      }
    }

    return newData;
  }

  nextPage = e => {
    console.log(this.state.currentPage)
    if (this.state.currentPage < maxPage) {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
    else {
      console.log("Error: maxPage reached")
    }
  }

  prevPage = e => {
    if (this.state.currentPage > 0) {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
    else {
      console.log("Error: first page reached")
    }
  }

  formFilterChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    })
    this.rebuildActiveData();
  }


  formListFilterChange = event => {
    const target = event.target;
    const checked = target.checked;
    const value = target.value;
    const name = target.name;

    if (checked) {
      const updatedList = this.state[name].concat(value);
      this.setState({
        [name]: updatedList
      })
    }
    else {
      const updatedList = this.state[name].filter(e => e !== value);
      this.setState({
        [name]: updatedList
      })
    }
    
    this.rebuildActiveData();
  }

  formListFilterCheck(name, value) {
    return this.state[name].includes(value);
  }

  formPage(pageNumber) {
    const { classes } = this.props;


    switch(pageNumber) {
      case 0:
          return (
            <FormGroup column>
              <Typography variant="h6" className={classes.formText}>
                Do you need a C2 that supports multiple users and/or exposes an API?
              </Typography>
              <FormControlLabel 
                control={
                  <Checkbox name="multiUserFilter" checked={this.state.multiUserFilter} onChange={this.formFilterChange} value="multiUser" />
                }
                label="Multiple Users"
              />
              <FormControlLabel 
                control={
                  <Checkbox name="apiFilter" checked={this.state.apiFilter} onChange={this.formFilterChange} value="api" />
                }
                label="Exposed API"
              />
            </FormGroup>
          )

      case 1:
        const channelsList = ["tcp", "http", "http2", "http3", "dns", "doh", "icmp", "ftp", "imap","mapi", "smb"];
        const channelsTitleList = ["TCP", "HTTP", "HTTP2", "HTTP3", "DNS", "DoH", "ICMP", "FTP", "IMAP", "MAPI", "SMB"];
        return (
          <React.Fragment>
            <Typography variant="h6" className={classes.formText}>
            What channels do you need to call back on?
            </Typography>
            <FormGroup row>
              {channelsList.map((channel, i) => (
                <FormControlLabel 
                  control={
                    <Checkbox name="channelsFilter" checked={this.formListFilterCheck("channelsFilter", channel)} onChange={this.formListFilterChange} value={channel} />
                  }
                  label={channelsTitleList[i]}
                />
              ))}
            </FormGroup>
          </React.Fragment>
        )

      case 2:
   
          return (
            <FormGroup column>
              <Typography variant="h6" className={classes.formText}>
                Which operating systems do you need your agents to run on?
              </Typography>
              <FormControlLabel 
                control={
                  <Checkbox name="agentOSFilter" checked={this.formListFilterCheck("agentOSFilter", "windows")} onChange={this.formListFilterChange} value="windows" />
                }
                label="Windows"
              />
              <FormControlLabel 
                control={
                  <Checkbox name="agentOSFilter" checked={this.formListFilterCheck("agentOSFilter", "linux")} onChange={this.formListFilterChange} value="linux" />
                }
                label="Linux"
              />
              <FormControlLabel 
                control={
                  <Checkbox name="agentOSFilter" checked={this.formListFilterCheck("agentOSFilter", "macos")} onChange={this.formListFilterChange} value="macos" />
                }
                label="MacOS"
              />
            </FormGroup>
          )
      case 3:
        const miscCapList = ["stego", "proxy_aware", "custom_profile", "jitter", "working_hours", "kill_date", "chaining_p2p", "logging", "attack_map", "domainfront"];
        const miscCapTitlesList = ["Stego", "Proxy Aware", "Custom Profiles", "Jitter", "Working Hours", "Kill Date", "Chaining / P2P Connections", "Logging", "MITRE ATT&CK Mapping", "DomainFront"];
        return (
          <React.Fragment>
            <Typography variant="h6" className={classes.formText}>
              What other capabilities do you require?
            </Typography>
            <FormGroup row>
              {miscCapList.map((cap, i) => (
                <FormControlLabel 
                  control={
                    <Checkbox name="miscCapFilter" checked={this.formListFilterCheck("miscCapFilter", cap)} onChange={this.formListFilterChange} value={cap} />
                  }
                  label={miscCapTitlesList[i]}
                />
              ))}
            </FormGroup>
          </React.Fragment>
        )

      case 4:

          return (
            <FormGroup column>
              <Typography variant="h6" className={classes.formText}>
                Which interfaces do you prefer?
              </Typography>
              <FormControlLabel 
                control={
                  <Checkbox name="uiFilter" checked={this.formListFilterCheck("uiFilter", "web")} onChange={this.formListFilterChange} value="web" />
                }
                label="Web"
              />
              <FormControlLabel 
                control={
                  <Checkbox name="uiFilter" checked={this.formListFilterCheck("uiFilter", "gui")} onChange={this.formListFilterChange} value="gui" />
                }
                label="GUI"
              />
              <FormControlLabel 
                control={
                  <Checkbox name="uiFilter" checked={this.formListFilterCheck("uiFilter", "cli")} onChange={this.formListFilterChange} value="cli" />
                }
                label="CLI"
              />
            </FormGroup>
          )
      

      case 5:
          const supportList = ["slack", "twitter", "github", "website", "guide"]
        const supportTitlesList = ["Slack", "Twitter", "GitHub", "Website", "Setup Guide"]
        return (
          <React.Fragment>
            <Typography variant="h6" className={classes.formText}>
              What type of support do you prefer?
            </Typography>
            <FormGroup row>
              {supportList.map((support, i) => (
                <FormControlLabel 
                  control={
                    <Checkbox name="supportFilter" checked={this.formListFilterCheck("supportFilter", support)} onChange={this.formListFilterChange} value={support} />
                  }
                  label={supportTitlesList[i]}
                />
              ))}
              <FormControlLabel 
                control={
                  <Checkbox name="activeFilter" checked={this.state.activeFilter} onChange={this.formFilterChange} value="active" />
                }
                label="Active Project"
              />
            </FormGroup>
          </React.Fragment>
        )

      case 6:
        return (
          <React.Fragment>
            <Typography variant="h6" className={classes.formText}>
            Learn more at the C2 Matrix Site!
            <br></br>
            <a href="https://thec2matrix.com">https://thec2matrix.com</a>
            </Typography>

          </React.Fragment>
        )



      default:
        this.setState({ currentPage: 0 });
        break;
    }
  }

  render() {
    const { classes } = this.props;
    const activeData = this.rebuildActiveData();

    return (
      <React.Fragment>
        <Paper>
          <div className={classes.table}>
            <Table stickyHeader aria-label="c2_table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(activeData).length < 1 &&
                  <TableCell key={0} >
                    No matching C2s
                  </TableCell>
                }

                {Object.entries(activeData).map( ([c2, values]) => (
                  <TableRow tabIndex={-1} key={c2}>
                    {columns.map(column => {
                      var value = values[column.id];
                      if (column.id === "name") {
                        value = c2;
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Paper>
        <div style={{ padding: "20px" }}>
          <Paper className={classes.form}>
            <Container sm className={classes.form}>
              {this.formPage(this.state.currentPage)}
            </Container>
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item>
                <div className={classes.formButton}>
                  {this.state.currentPage > 0 && <Button onClick={this.prevPage} variant="contained"> Prev </Button>}
                </div>
              </Grid>
              <Grid item>
                <div className={classes.formButton}>
                  {this.state.currentPage < maxPage && <Button onClick={this.nextPage} variant="contained" color="primary"> Next </Button>}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </div>
    </React.Fragment>
    )
  }
}


export default withStyles(styles)(C2Table);