import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MainAppBar from '../components/MainAppBar';
import C2Table from '../components/C2Table';


const styles = theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: "80vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  toolbar: theme.mixins.toolbar,
});


class AppMain extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <MainAppBar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <C2Table />
        </main>
      </React.Fragment>
    )
  }
}


export default withStyles(styles)(AppMain);