import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Logo from "./logo.png";




const styles = theme => ({
  
  appBar: {
    zIndex: 99,
  },
  title: {
    flexGrow: 1,
    paddingLeft: '40px',
    padding: '20px',
  },
});


class MainAppBar extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <AppBar position="fixed" className={classes.appBar}>
       
        <Typography variant="h6" className={classes.title}>
          Ask The C2 Matrix
        </Typography>
      </AppBar>
    )
  }
}


export default withStyles(styles)(MainAppBar);